import React from 'react';
import useFetch from '../hooks/useFetch.js';

const Blog = () => {
  const { data: posts, loading, error } = useFetch('/wordpress/wp-json/wp/v2/posts');

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div>
      <h1>Blog</h1>
      {posts.map(post => (
        <div key={post.id}>
          <h2>{post.title.rendered}</h2>
          <div dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
        </div>
      ))}
    </div>
  );
};

export default Blog;
