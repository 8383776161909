import React from 'react';
import useFetch from '../hooks/useFetch.js';

const Conditions = () => {
  const { data: conditions, loading, error } = useFetch('/wordpress/wp-json/wp/v2/condition?per_page=100');

  if (loading) return (
    <div className="loading">
      <p>Loading conditions...</p>
    </div>
  );
  
  if (error) return (
    <div className="error">
      <p>Error loading conditions: {error.message}</p>
    </div>
  );

  if (!conditions || conditions.length === 0) return (
    <div className="no-content">
      <p>No conditions found.</p>
    </div>
  );

  return (
    <div className="conditions-container">
      <h1>Medical Conditions</h1>
      <div className="conditions-grid">
        {conditions.map(condition => (
          <div key={condition.id} className="condition-card">
            {condition.featured_media && (
              <div className="condition-image">
                <img src={condition._embedded?.['wp:featuredmedia']?.[0]?.source_url} 
                     alt={condition.title.rendered} />
              </div>
            )}
            <h2>{condition.title.rendered}</h2>
            <div 
              className="condition-content"
              dangerouslySetInnerHTML={{ __html: condition.content.rendered }} 
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Conditions;
